import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import NavigationItems from './navigation'
import smallIcon from '../images/logo-icon.svg'

const Header = () => {
  return (
    <header>
      <nav
        css={css`
          width: 100%;
          z-index: 999;
        `}
      >
        <div
          css={css`
            max-width: 960px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0 1rem;

            @media (max-width: 720px) {
              justify-content: space-between;
            }
          `}
        >
          <Link activeClassName="none" to="/">
            <img
              style={{ margin: 0 }}
              width="60"
              src={smallIcon}
              alt="Logo Yogastudio Olga Hilz"
            ></img>
          </Link>

          <div
            css={css`
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;

              @media (max-width: 720px) {
                display: none;
              }
            `}
          >
            <ul
              css={css`
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            >
              <NavigationItems />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
