import React from 'react'
import styled from '@emotion/styled'

const BackgroundImage = styled.div`
  background-image: url(${(props) => props.url});
  transition: background-image 1.5s linear;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const BackgroundHero = ({ children, className, imageData }) => {
  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#223521`}
      url={imageData.src}
    >
      {children}
    </BackgroundImage>
  )
}

const StyledBackgroundHero = styled(BackgroundHero)`
  height: ${(props) => (props.mobileHeight ? props.mobileHeight : '260px')};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 720px) {
    background-size: cover;
    height: ${(props) => (props.desktopHeight ? props.desktopHeight : '450px')};
  }
`

export default StyledBackgroundHero
