import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  padding: 1rem 1.5rem;

  @media (max-width: 720px) {
    padding: 0;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }

  &.active {
    background: rgba(0, 0, 0, 0.3);
  }
`

const NavigationItems = () => (
  <>
    <li>
      <NavLink activeClassName="active" to="/kurse">
        Hatha Yoga
      </NavLink>
    </li>
    <li>
      <NavLink activeClassName="active" to="/hatha-yoga-intense">
        Hatha Yoga intense
      </NavLink>
    </li>
    <li>
      <NavLink activeClassName="active" to="/blog">
        Yogisch Leben
      </NavLink>
    </li>
    <li>
      <NavLink activeClassName="active" to="/studio">
        Studio
      </NavLink>
    </li>
    {/* <li>
      <NavLink activeClassName="active" to="/ueber-uns">
        Über Uns
      </NavLink>
    </li> */}
    <li>
      <NavLink activeClassName="active" to="/kontakt">
        Kontakt
      </NavLink>
    </li>
  </>
)

export const ExtraNavigationItems = () => (
  <>
    {/* <li>
      <NavLink activeClassName="active" to="/buchen">
        <span role="img" aria-label="Hand Emoji zeigt nach rechts">
          👉
        </span>{' '}
        HIER BUCHEN
      </NavLink>
    </li> */}
    <li>
      <NavLink activeClassName="active" to="/impressum">
        Impressum
      </NavLink>
    </li>
  </>
)

export default NavigationItems
